<template>
  <footer class="footer bg-red ">
    <div class="container py-8">
      <div class="flex justify-between">
        <ul class="footer__socials flex">
          <li class="mr-3">
            <a href="https://www.instagram.com/holy_chic.md/" target="_blank" class="hover:scale-125 transform origin-center ease-in-out duration-300">
              <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.6545 5.7103C22.6509 5.7103 21.8289 6.52358 21.8289 7.53586C21.8289 8.53949 22.6422 9.36143 23.6545 9.36143C24.6581 9.36143 25.48 8.54814 25.48 7.53586C25.48 6.52358 24.6581 5.7103 23.6545 5.7103ZM15.5476 8.04633C11.3254 8.04633 7.89059 11.4812 7.89059 15.7033C7.89059 19.9255 11.3254 23.3603 15.5476 23.3603C19.7697 23.3603 23.2046 19.9255 23.2046 15.7033C23.2046 11.4812 19.7697 8.04633 15.5476 8.04633ZM15.5476 20.6003C12.8395 20.6003 10.6419 18.4027 10.6419 15.6947C10.6419 12.9866 12.8395 10.789 15.5476 10.789C18.2556 10.789 20.4532 12.9866 20.4532 15.6947C20.4532 18.4027 18.2556 20.6003 15.5476 20.6003ZM31 9.33547C31 4.1789 26.8211 0 21.6645 0H9.33547C4.1789 0 0 4.1789 0 9.33547V21.6559C0 26.8211 4.1789 31 9.33547 31H21.6559C26.8124 31 30.9913 26.8211 30.9913 21.6645V9.33547H31ZM28.0756 21.6645C28.0756 25.2032 25.2032 28.0756 21.6645 28.0756H9.33547C5.79682 28.0756 2.92437 25.2032 2.92437 21.6645V9.33547C2.92437 5.79682 5.79682 2.92437 9.33547 2.92437H21.6559C25.1945 2.92437 28.067 5.79682 28.067 9.33547V21.6645H28.0756Z" />
              </svg>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/holychic.md/" target="_blank" class="hover:scale-125 transform origin-center ease-in-out duration-300">
              <svg viewBox="0 0 19 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.91995 20.762C3.1525 20.762 1.61449 20.762 0 20.762C0 18.5548 0 16.4426 0 14.1768C1.56067 14.1768 3.12701 14.1768 4.89162 14.1768C4.89162 12.5647 4.97093 11.0951 4.87746 9.63947C4.48658 3.47334 8.21974 0.168159 14.1962 0.00611306C15.7795 -0.0357954 17.3657 0.148602 18.9972 0.229625C18.9972 2.29431 18.9972 4.25842 18.9972 6.37899C17.5555 6.37899 16.21 6.35385 14.8646 6.38458C12.6157 6.43766 11.9671 7.11658 11.9614 9.3489C11.9557 10.8716 11.9614 12.3942 11.9614 14.0874C14.4001 14.0874 16.768 14.0874 18.9717 14.0874C18.9717 16.3756 18.9717 18.4905 18.9717 20.7536C16.5556 20.7536 14.3293 20.7536 11.9869 20.7536C11.9869 26.9057 11.9869 32.907 11.9869 38.9977C9.5963 38.9977 7.32469 38.9977 4.91711 38.9977C4.91995 32.9573 4.91995 26.9588 4.91995 20.762Z" />
              </svg>
            </a>
          </li>
        </ul>

        <a href="tel:+37360343478" class="block text-white font-qanelas-bold text-lg hover:underline">+373 603 43 478</a>
        
      </div>
    </div>
    <div class="sign bg-white py-2 text-center global-text-sm text-sm ">
      Developed by - <a href="https://xsort.md/" target="_blank" class="font-pt-mono text-red">Xsort Web Studio</a>
    </div>
  </footer>  
</template>
<script>
  export default {
    name: 'Footer'
  }
</script>
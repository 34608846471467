<template>
  <div class="slider relative lg:mb-3">
    <agile v-if="!isInstagramApp()" :options="options">
      <div v-for="(slide, index) in slides">
        <img :src="slide.img" alt="11" class="block w-full">
        <div class="container slider__text-wrap">
          <p class="font-qanelas-regular global-global-text-sm text-sm  global-text-sm text-sm   py-1 lg:text-base lg:text-center lg:py-3">
            {{slide.text}}
          </p>
        </div>
      </div>
    </agile>

    <agile v-if="isInstagramApp()" :options="options_instagram">
      <div v-for="(slide, index) in slides">
        <img :src="slide.img" alt="11" class="block w-full">
        <div class="container slider__text-wrap">
          <p class="font-qanelas-regular global-global-text-sm text-sm  global-text-sm text-sm   py-1 lg:text-base lg:text-center lg:py-3">
            {{slide.text}}
          </p>
        </div>
      </div>
    </agile>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
export default {
  name: 'Slider',
  props: {
    slides: {
      type: Array,
      default: function (){
        return [];
      }
    }
  },
  data() {
    return {
      options:{
        dots: true,
        autoplaySpeed: 4000,
        autoplay: true,
        navButtons: true,
        responsive: [
          {
            breakpoint: 0,
            settings: {
              slidesToShow: 1
            }
          },

          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      },

      options_instagram:{
        dots: true,
        autoplaySpeed: 4000,
        autoplay: true,
        navButtons: true,
        responsive: [
          {
            breakpoint: 0,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    }
  },
  methods:{
    isInstagramApp(){
      let ua = navigator.userAgent || navigator.vendor;

      return ua.indexOf('Instagram') !== -1;
    }
  },
  components: {
    agile: VueAgile
  }
}
</script>

<style lang="scss">
  .slider{
    @screen sm{
      @apply mx-auto;
    }
    @screen md{

    }
    @screen lg{
      max-width: 1000px;
    }
    @screen xl{
      max-width: 1200px;
    }
  }

  .agile__dot{
    width: 6px;
    height: 6px;
    @apply rounded-full bg-white;


    &:not(:last-child){
      margin-right: 5px;
    }

    &--current{
      @apply opacity-50;
    }

    //agile__dot--current agile__dot

  }

  .agile__actions{
    @apply absolute;
    bottom: 90px;
    right: 15px;
  }


  .agile__nav-button{
    @apply hidden;
  }

  .slider__text-wrap{
    min-height: 3.2em;
  }

</style>
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '../i18n'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title: `HOLY CHIC`}
  },
  {
    path: '/about',
    name: 'About',
    meta: {title: `HOLY CHIC - About`},
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/evgenia-gruzdeva',
    name: 'EvgeniaGruzdeva',
    meta: {title: `HOLY CHIC - Evgenia Gruzdeva`},
    component: () => import('@/views/EvgeniaGruzdeva.vue')
  },
  {
    path: '/brands',
    name: 'Brands',
    meta: {title: `HOLY CHIC - Brands`},
    component: () => import('@/views/Brands.vue')
  },
  {
    path: '/rules',
    name: 'Rules',
    meta: {title: `HOLY CHIC - Rules`},
    component: () => import('@/views/Rules.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(to.hash){
      setTimeout(() => {
        document.querySelector(to.hash).scrollIntoView({behavior: "smooth"})
      }, 300)
    }else{
      document.getElementById('app').scrollIntoView();
    }

  }
})

router.beforeEach((to, from, next) => {

  document.title = to.meta.title
  /*const locale = to.params.locale;
  const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')

  if(supported_locales.includes(locale)) return next('ru');

  if(i18n.locale !== locale){
    i18n.locale = locale;
  }*/

  return  next()
})


export default router

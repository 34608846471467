<template>
  <div class="home">
    <div class="text-2xl font-bold">
      <slider-component :slides="[
        {
          img: '/imgs/content/slides/slide1.jpg',
          text: $t('slide_1'),
        },
        {
          img: '/imgs/content/slides/slide2.jpg',
          text: $t('slide_2'),
        },
        {
          img: '/imgs/content/slides/slide3.jpg',
          text: $t('slide_3'),
        },
        {
          img: '/imgs/content/slides/slide4.jpg',
          text: $t('slide_4'),
        }
      ]"
      >
      </slider-component>

      <div class="bg-red trigger-block text-white">
        <div class="container">
          <div class="trigger-block__link-wrap">
            <router-link to="/about" class="block">
              <section class="trigger-block__link">
                <div class="trigger-block__link-text ">
                  <h2 class="font-pt-mono text-3xl">
                    {{ $t('about') }}
                  </h2>
                  <p class="global-text-sm text-sm ">
                    {{ $t('about_resale_studio') }}
                  </p>
                </div>
                <picture class="trigger-block__arrow" >
                  <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
                    <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
                    <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
                  </svg>
                </picture>
              </section>
            </router-link>
          </div>
          <p class="trigger-block__under-text border-white">
            {{ $t('when_nowhere_hang') }}
          </p>

        </div>
      </div>

      <div class="bg-bush trigger-block">
        <div class="container">
          <div class="trigger-block__link-wrap">
            <router-link to="/rules" class="block">
              <section href="#" class="trigger-block__link">
                <div class="trigger-block__link-text ">
                  <h2 class="font-pt-mono text-3xl">
                    {{ $t('rules') }}
                  </h2>
                  <p class="global-text-sm text-sm ">
                    {{ $t('of_admission') }}
                  </p>
                </div>
                <picture class="trigger-block__arrow trigger-block__arrow--black" >
                  <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
                    <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
                    <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
                  </svg>
                </picture>
              </section>
            </router-link>
          </div>
          <p class="trigger-block__under-text border-black">
            {{ $t('when_clothes') }}
          </p>
        </div>
      </div>

      <div class="bg-white trigger-block">
        <div class="container">
          <div class="trigger-block__link-wrap">
            <router-link to="/evgenia-gruzdeva" class="block">
              <section class="trigger-block__link" >
                <div class="trigger-block__link-text ">
                  <h2 class="font-pt-mono text-3xl" v-html="$t('founder_name_br')"></h2>
                  <p class="global-text-sm text-sm " v-html="$t('founder_and_permanent_br')"></p>
                </div>
                <picture class="trigger-block__arrow trigger-block__arrow--black" >
                  <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
                    <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
                    <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
                  </svg>
                </picture>
              </section>
            </router-link>

          </div>
          <p class="trigger-block__under-text border-black">
            {{ $t('how_resale_save') }}
          </p>

        </div>
      </div>

      <div class="img-block xl:mb-10">
        <picture class="block w-full">
          <img src="/imgs/content/photos/evgenia_gruzdeva_home.jpg" alt="Евгения Груздева" class="block w-full">
        </picture>
      </div>

      <div class="bg-red trigger-block text-white">
        <div class="container">
          <div class="trigger-block__link-wrap">
            <router-link to="/brands" class="block">
              <section class="trigger-block__link">
                <div class="trigger-block__link-text ">
                  <h2 class="font-pt-mono text-3xl" v-html="$t('brands_authentic_br')"></h2>
                  <p class="global-text-sm text-sm ">
                    {{ $t('take_dont_take') }}
                  </p>
                </div>
                <picture class="trigger-block__arrow" >
                  <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
                    <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
                    <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
                  </svg>
                </picture>
              </section>
            </router-link>
          </div>
          <p class="trigger-block__under-text border-white">{{ $t('wish_black_list') }}</p>
        </div>
      </div>

      <div class="bg-bush trigger-block">
        <div class="container">
          <div class="trigger-block__link-wrap">
            <router-link :to="{ path: '/rules', hash: '#formula' }" class="block">
              <section class="trigger-block__link">
                <div class="trigger-block__link-text ">
                  <h2 class="font-pt-mono text-3xl" v-html="$t('formula_br')"></h2>
                  <p class="global-text-sm text-sm ">
                    {{ $t('final_payment') }}
                  </p>
                </div>
                <picture class="trigger-block__arrow trigger-block__arrow--black" >
                  <svg viewBox="0 0 44 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.725647" y1="14.5246" x2="42.3172" y2="14.5246" stroke-width="1.0417"/>
                    <line x1="28.3124" y1="28.3901" x2="42.0261" y2="14.6764" stroke-width="1.0417"/>
                    <line x1="29.049" y1="1.04064" x2="42.7627" y2="14.7544" stroke-width="1.0417"/>
                  </svg>
                </picture>
              </section>
            </router-link>
          </div>
          <p class="trigger-block__under-text border-black">
            {{ $t('selling_price_commission_taxes') }}
          </p>
        </div>
      </div>

      <div class="contacts pt-8" id="contacts">
        <div class="container">
          <h2 class="text-2xl font-pt-mono mb-2">
            {{ $t('find_us') }}
          </h2>
          <p class="border-b border-black global-text-sm text-sm  pb-9 ">
            {{ $t('address_value') }} <br>
            <a class="block mt-1 hover:text-red" href="mailto:resale@holychic.club">resale@holychic.club</a>
            <a class="block mt-1 hover:text-red" href="tel:+37360343478">+373 603 43 478</a>
          </p>
          <div class="flex justify-between global-text-sm text-sm ">
            <a href="https://www.instagram.com/holy_chic.md/" target="_blank" class="hover:underline">
              <span class="text-red font-qanelas-bold">insta</span> @holy_chic.md
            </a>
            <a href="https://www.facebook.com/holychic.md/" target="_blank" class="hover:underline">
              <span class="text-red font-qanelas-bold">fb</span> @holychic.md
            </a>
          </div>
        </div>
        <div class="contacts__map mt-9">
          <div class="container mb-9">
            <h2 class="text-2xl font-pt-mono mb-2">{{$t('schedule')}}</h2>
            <p class="mb-3 global-text-sm text-sm font-qanelas font-semibold">{{ $t('schedule_values') }}</p>
          </div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d339.9874377298012!2d28.82644822153205!3d47.022577655311984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97dd7a87742c7%3A0x1505e54309a5f583!2sHoly%20Chic%20Resale%20Studio!5e0!3m2!1sen!2s!4v1640592654935!5m2!1sen!2s" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Slider from '@/components/Slider';
export default {
  name: "home",
  data() {
    return {

    }
  },
  components: {
    'slider-component' : Slider,
  },
}
</script>

<template>
  <header class="header">
    <div class="header-container" v-if="isHomeHeader">
      <div class="flex justify-between items-center py-7 md:py-3">
        <div class="header__logo-block">
          <router-link to="/" class="block w-full">
            <h1 class="block header__logo-link block text-0">
              <picture class="block w-ull">
                <img src="/imgs/logos/HolyChicLogo.svg" alt="Holy Chic logo" class="w-full block">
              </picture>
              <span class="hidden">Holy Chic</span>
            </h1>
          </router-link>
        </div>
        <div class="ml-auto mr-4">
          <locale-switcher></locale-switcher>
        </div>
        <div class="header__slogan-block">
          <picture class="block header__slogan w-full">
            <img src="/imgs/logos/review_resale_resolve.svg" alt="Holy Chic slogan" class="w-full block">
          </picture>
        </div>
      </div>
    </div>
    <div class="header-container-2" v-else>
      <div class="flex justify-between items-center py-7" >
        <div class="header__logo-block-2">
          <router-link to="/"  class="block w-full">
            <h1 class="block header__logo-link-2 block text-0">
              <picture class="block w-ull">
                <img src="/imgs/logos/HolyChicLogo2.svg" alt="Holy Chic logo" class="w-full block">
              </picture>
              <span class="hidden">Holy Chic</span>
            </h1>
          </router-link>
        </div>
        <div class="ml-auto mr-4">
          <locale-switcher></locale-switcher>
        </div>
        <div class="header__button-block">
          <button type="button" class="header__button-btn flex justify-center items-center"  @click="$store.getters.menuCanvas.toggle()">
            <svg  viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.350677" y1="1.24382" x2="21.1856" y2="1.24382" stroke="black" stroke-width="2.08341"/>
              <line x1="0.350677" y1="8.01482" x2="21.1856" y2="8.01482" stroke="black" stroke-width="2.08341"/>
              <line x1="0.350677" y1="14.2651" x2="21.1856" y2="14.2651" stroke="black" stroke-width="2.08341"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import LocaleSwitcher from '@/components/partials/LocaleSwitcher';
export default {
  name: 'Header',

  props: {
    isHomeHeader: {
      type: Boolean,
      default: function (){
        return false
      }
    }
  },

  components:{
    'locale-switcher' : LocaleSwitcher,
  }

}
</script>

<style scoped lang="scss">
  .agile__slides{
    div{
      width: 10px;
      height: 10px;
      background: red;
    }
  }
</style>

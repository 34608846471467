<template>
  <ul class="flex">
    <li v-for="locale in locales" :key="locale">
      <span class=" cursor-pointer px-1 block">
        <span class="flex h-8 w-8 flex justify-center items-center font-bold rounded-full "
          :class="{ 'bg-red text-white hover:text-white': $i18n.locale === locale, 'hover:text-red': $i18n.locale !== locale }"
          @click="switchLocale(locale)">
          {{ locale }}
        </span>

      </span>
    </li>
  </ul>
</template>

<script>
export default {

  name: 'LocaleSwitcher',

  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    }
  },

  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;

      }
    }
  }
}
</script>
<template>
  <div>
    <header-component :is-home-header="$route.name === 'Home'"></header-component>
    <menu-component></menu-component>
    <router-view />
    <footer-component></footer-component>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue';
import Header from '@/components/partials/Header.vue';
import Footer from '@/components/partials/Footer.vue';
import Slider from '@/components/Slider';
import Menu from '@/components/partials/Menu';
import LocaleSwitcher from '@/components/partials/LocaleSwitcher';
export default {
  name: 'main-layout',
  data: () => ({

  }),
  components: {
    HelloWorld,
    'header-component' : Header,
    'footer-component' : Footer,
    'slider-component' : Slider,
    'menu-component' : Menu,
    'locale-switcher' : LocaleSwitcher,
  },
  computed: {

  }
}
</script>

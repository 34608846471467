export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privet i18n !!"])},
  "slide_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссионный магазин дизайнерских вещей. Только оригиналы Middle, Premium и Lux сегмента"])},
  "slide_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые, либо как новые. Люксовые бренды со скидкой до 90%"])},
  "slide_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У нас можно выгодно разгрузить и пополнить свой гардероб"])},
  "slide_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторская подборка ассортимента. Профессиональная проверка на подлинность."])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эбаут"])},
  "about_resale_studio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["о сервисе ресейл студии"])},
  "when_nowhere_hang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда некуда вешать и нечего носить"])},
  "rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правила"])},
  "of_admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["приема товаров на реализацию"])},
  "when_clothes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда вещи истратили ваш кредит восторга"])},
  "founder_name_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Евгения <br/> Груздева "])},
  "founder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Евгения Груздева "])},
  "founder_and_permanent_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основательница <br/> и руководитель Holy Chic Studio "])},
  "founder_and_permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основательница и руководитель Holy Chic Studio "])},
  "title_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С чего все началось: "])},
  "article1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За неделю до родов, уходя в краткосрочный декрет, я решила протестировать идею, которая долгое время не давала мне покоя. Мой кабинет, который должен был пустовать какое-то время, стал испытательным полигоном. Четвертый этаж без лифта и парковки, 16 квадратных метров, два рейла и шкаф с обувью — с этого начинался Holy Chic."])},
  "article2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идею подсказали собственные потребности. Не могу сказать, что она принадлежала персонально мне. В Америке и Европе сервис по перепродаже предметов личного гардероба к тому моменту существовал уже не один десяток лет, у нас же ничего, кроме объявлений на «трех девятках», не было."])},
  "article3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В 2018 году о ресейле никто не слышал, нам пришлось формировать рынок и отношение к сервису с нуля."])},
  "article4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["А еще бороться со стереотипами, лавировать между покупательной способностью и реальной стоимостью товаров, которые нам привозили. Но сложнее всего было нарабатывать базу поставщиков. Женский гардероб — это сугубо личное пространство, чужих туда не пускают, надо сначала заслужить доверие. Помогло сарафанное радио: оно сделало свое дело, и нас стали рекомендовать, советовать подругам."])},
  "article5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего через несколько месяцев мы уже были вынуждены переехать в помещение больше: спрос превысил ожидания, а отзывы так вдохновляли, обратная связь была такой искренней, что я поняла: надо расти."])},
  "article6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На данный момент мы занимаем 220 м2 в центре города, и только за последний год наш оборот увеличился втрое. Проект ни разу не привлекал сторонних инвестиций, не получал грантов, не брал кредитов, и за весь период существования не было ни одного убыточного месяца."])},
  "article7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нулевой углеродный след:"])},
  "article8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня невозможно оставаться в стороне от вопросов экологии и этичного потребления. Меня они стали особенно беспокоить после рождения ребенка. Тогда невольно начинаешь спрашивать себя: а что ты сделала для того, чтобы твой ребенок жил в лучшем мире? Я предпочитаю всегда начинать с «собственного огорода», и мой бизнес каждый день вносит маленький вклад в борьбу с перепроизводством."])},
  "article9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы занимаемся самым экологичным направлением в фэшн-индустрии: ничего не производим и ничего не закупаем, соответственно, у нас нет отходов и нераспроданных остатков. Мы предлагаем повторное использование товаров и делаем акцент на качественных вещах, которые прослужат долго своим новым владельцам."])},
  "article10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За весь период существования Holy Chic мы подарили вторую жизнь 4 000 платьев, 7 500 парам обуви и 3 400 сумкам. В пересчете на сэкономленные ресурсы это миллионы тонн воды, килоджоулей энергии и литров горючего. Такая статистика не может не радовать, но я бы не стала сводить суть нашего сервиса только к экологии…"])},
  "article11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клондайк для азартных модниц:"])},
  "article12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В условиях дефицита представленных в Кишиневе брендов мы — глоток свежего воздуха для женщин, которые любят эксклюзивные дизайнерские вещи. Только в Holy Chic можно разжиться архивными коллекциями Celine, Maison Margiela, Dior и Chanel, купить редкий платок Hermes или пару самых трендовых босоножек текущего сезона с большой скидкой. Но заглядывать к нам стоит не только в поиске люксовых брендов и раритетов, качественные вещи middle-premium сегмента также присутствуют в ассортименте. "])},
  "article13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для людей, разбирающихся в винтаже и коллаборациях, наш магазин — настоящий сундук с сокровищами."])},
  "article14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставщики, которые с нами давно, уже при покупке новой вещи прикидывают, по какой цене ее можно будет продать. Относятся к вещи бережно и, получив удовольствие, передают дальше. А это уже определенная осознанность. Чувствуется смена парадигмы в отношении к шопингу."])},
  "article15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О чудесах:"])},
  "article16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если бы Вы знали, какие чудеса творятся у нас в студии. Скольких выпускниц мы одели в Dior, сколько великолепных сумок вновь стали предметами гордости и обожания! Вы бы видели глаза женщины, которая покупает туфли своей мечты! Manolo Blahnik, как у Кэрри Бредшоу, или Chanel, стоящий космических денег в бутике, поэтому недоступный среднему классу. Кому-то они просто не подошли по размеру. Труд талантливейших дизайнеров, мастеров и чего уж там, маркетологов, был обречен лежать в гардеробной на верхней полке…"])},
  "article_final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если Вы столкнулись с теми же проблемами, что и сотни наших поставщиков и клиентов, мы всегда рады оказаться Вам полезными. Все условия нашего сервиса подробно описаны на нашем сайте www.holychic.club. А также рекомендуем подписаться на наш инстаграм holy_chic.md и присоединиться к нашему сообществу модниц-охотниц. Тратьте деньги на путешествия, образование, семью, на то, что делает вас счастливыми. Если одежда не радует, меняйте ее, с нами это просто и очень увлекательно."])},
  "how_resale_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" О том, как ресейл спасет мир "])},
  "brands_authentic_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Бренды <br/>  и аутентик "])},
  "brands_authentic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Бренды и аутентик "])},
  "take_dont_take": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["что берем, что не берем, как проверяем "])},
  "wish_black_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Wish list, black list и война против подделок "])},
  "formula_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Формула <br/> расчета "])},
  "formula": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Формула расчета "])},
  "final_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["финальной выплаты поставщику"])},
  "selling_price_commission_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажная цена, комиссион и налоги"])},
  "find_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как нас найти"])},
  "address_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["г. Кишинев, ул. М.Г. Бэнулеску Бодони, 27 (вход с ул. Букурешть)"])},
  "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["График работы"])},
  "schedule_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежедневно, с 10.00 до 20.00"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
  "about_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holy Chic Studio – это авторский ресейл проект, который успешно существует на рынке Кишинева более 5 лет и является флагманом этой индустрии в нашей стране."])},
  "about_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наш сервис позволяет купить и/или продать дизайнерские предметы женского гардероба"])},
  "about_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вся деятельность нашей студии построена на неисчерпаемой любви к красивым вещам, осознанном потреблении и желании решить извечную женскую проблему: «Когда некуда вешать и нечего носить»"])},
  "about_par_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы являемся раскрученной площадкой с уютным магазином в центре города и большим количеством лояльных покупателей."])},
  "about_par_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать в мир ресейла!"])},
  "familiarize_rules_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ознакомиться <br/> с правилами"])},
  "go_back_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться <br/>назад"])},
  "rules_subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итак, Вы решили продать вещи, которые истратили ваш личный кредит восторга."])},
  "rules_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прежде, чем Вы предложите их к перепродаже советуем пропустить каждую позицию через следующие фильтры:"])},
  "rules_subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Степень износа:"])},
  "rules_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы отдаем предпочтение новым либо почти новым вещам. К перепродаже не допускаются вещи с видимыми дефектами."])},
  "rules_subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актуальность <br/> модели:"])},
  "rules_par_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша ресейл студия очень щепетильно относится к ассортименту. Ключевое правило, которым мы рекомендуем пользоваться – не пытайтесь продать то, что сами бы не захотели купить."])},
  "rules_subtitle_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд:"])},
  "rules_par_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качественная дизайнерская вещь способна пережить несколько владельцев и сохранить свой первоначальный вид при условии должного обращения и ухода. Именно поэтому мы отдаем предпочтение сегментам Middle-Premium, Premium и Lux."])},
  "rules_subtitle_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительное согласование:"])},
  "rules_par_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итак, вещи отобраны, берем в руки телефон и делаем любительские снимки каждой позиции. На фото должно быть изделие целиком и бренд – этого нам будет достаточно, чтобы сориентировать Вас."])},
  "rules_par_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлите фото нам в директ."])},
  "rules_par_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из нашей переписки Вам станет понятно какие вещи мы возьмем на реализацию, а также сразу сориентируем по ценам."])},
  "rules_par_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписывая контракт Вы даёте согласие на участие ваших вещей в фото и видео съемках, проводимых с целью привлечения потенциальных покупателей."])},
  "rules_conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процесс отправки:"])},
  "rules_conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы принимаем вещи на реализацию на 2 месяца по предварительно согласованной стоимости, которая зафиксированна в акте приема ,составленном и подписанном между нами в момент когда Вы приносите вещи в студию."])},
  "rules_conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценку товаров осуществляют наши эксперты, которые проводят  кросс -анализ каждого лота по 7-ми показателям:"])},
  "rules_conditions_sub_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актуальность модели"])},
  "rules_conditions_sub_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние"])},
  "rules_conditions_sub_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость в год анонса"])},
  "rules_conditions_sub_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость в период скидок"])},
  "rules_conditions_sub_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость на европейском и американском ресейле"])},
  "rules_conditions_sub_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость у байеров"])},
  "rules_conditions_sub_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принадлежность к категории винтажа либо редких коллабораций"])},
  "rules_conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы можем решить обратиться к независимой службе аутентификации для определения подлинности товаров. Если мы решим это сделать, менеджер Holy Chic свяжется с вами для одобрения данной услуги. Если товар будет признан подлинным, стоимость аутентификаци является нашим расходом. Если товар не будет признан подлинным, стоимость этой процедуры (от 30 до 50 Евро, в зависимости от бренда, ложится на поставщика и должна быть оплачена им в момент возврата товара). Вам будет предложено забрать ваши товары, и Вам придется покрыть расходы по аутентификации."])},
  "rules_conditions_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Важно:"])},
  "rules_conditions_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы оставляем за собой право выборочной публикации ассортимента на нашей страничке в инстаграмм."])},
  "rules_conditions_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет за проданные позиции производится наличными у нас в студии."])},
  "rules_conditions_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае, если нам не удается реализовать вещи в течении 2-х месяцев мы предлагаем произвести уценку в размере 20% от согласованной ранее стоимости и вновь пытаемся подарить вашим вещам вторую жизнь."])},
  "rules_conditions_9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат:"])},
  "rules_conditions_10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае, если нам не удается реализовать ваши вещи в течении месяца после уценки мы оформляем вещи на возврат и уведомляем Вас об этом посредством звонка/смс/мэйл."])},
  "rules_conditions_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У нас есть опция доставки возвратов через нашего курьера и эта услуга абсолютно бесплатна для наших поставщиков. Так как наши складские помещения не рассчитаны на длительное хранение возвратов мы очень просим не затягивать и забрать ваши вещи в ближайшее время после уведомления о возврате."])},
  "rules_conditions_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наш менеджер будет настойчиво об этом просить в течении трех недель, в случае если мы не получим от Вас ответа и Вы не придете за вещами в течении трех недель регулярных уведомлений, мы оставляем за собой право вернуть ваши вещи с помощью курьерской службы на указанный в контракте адрес. Стоимость данной услуги берёт на себя наша компания."])},
  "rules_conditions_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарим Вас за осознанный подход к своему гардеробу и бережное отношение к нашей Планете."])},
  "send_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить <br/> фото вещи на <br/> согласование"])},
  "from_final_cost_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["от финальной стоимости товара <br/> составят комиссионные магазина "])},
  "income_tax_br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["составлит подоходный налог*, <br/> который будет удержан из остатка <br/> суммы после вычета комиссионных"])},
  "income_tax_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Подоходный налог. Статья 90-1. Окончательное удержание налога из некоторых видов доходов. Каждый комиссионер удерживает налог в размере 12% из выплат, осуществленных в пользу физического лица."])},
  "work_brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренды, c<br/>которыми мы <br/> работаем"])},
  "founder_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Когда три года назад мы открывали Holy Chic я и представить не могла как его ждали!"])},
  "founder_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестовый формат проекта разместился в моем бывшем кабинете на 12-ти квадратных метрах!"])},
  "founder_par_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы установили два рейла с одеждой и сколотили шкаф для обуви. Уже через пару месяцев нам пришлось переезжать в помещение побольше. Спрос был огромный, отзывы очень вдохновляли, обратная связь была такая заряженная и искренняя, что я поняла – надо расти."])},
  "wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вишлист"])},
  "wishlist_par_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы отдаем предпочтение сегментам Middle Up, Premium и Lux. Список брендов, приведенный ниже, поможет Вам сориентироваться."])},
  "ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В HolyChic <br/> запрещена продажа <br/> любого рода копий <br/> и реплик"])},
  "wishlist_par_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все товары проходят проверку на подлинность командой профессиональных аутентификаторов. Также, мы являемся единственными в стране, обладателями гаджета машинной аутентификации Entrupy."])},
  "blacklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блэклист"])},
  "ban_par": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы не работаем с вещами из категории масс-маркет, а также hand-made и неизвестных марок. Нам не стоит предлагать такие бренды как:"])},
  "etc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["и тд."])}
}